import { setToken, setUserInfo, setUserIdentity } from '@/core/cache/auth';
import ClientApp from '@/client/index';
import { saveDeviceAsync } from './utils/deviceId';

export const syncDriverInfo = async () => {
  saveDeviceAsync();
  try {
    const res = await ClientApp.requestToken();
    const { access_token } = res;
    // TODO: 还需要持续缓存用户身份信息
    if (access_token) {
      setToken(access_token);
    }
    setUserInfo(res);
    setUserIdentity(res.identity);
    return res;
  } catch (error) {
    return null;
  }
};
