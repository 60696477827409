export const whiteList = {
  '/driver/waybill': { index: 0 },
  '/driver/source': { index: 1 },
  '/service': { index: 3 },
  '/mine': { index: 4 }
};

export const noLoginWhiteList = ['/download', '/mine/message', '/agreement'];

export const RealNameAuthStatus = {
  Unknow: -1,
  Unauthenticated: 0, //认证失败
  Finish: 1, //认证通过
  Certification: 2 //认证中
};

export const DriverAtuhStatus = {
  Unknow: -1,
  Waiting: 1, //待审核
  Finish: 2, //已审核
  Fail: 3 //审核不通过
};

export const TruckAuthStatus = {
  Unknow: -1,
  Waiting: 1, //待审核
  Finish: 2, //已审核
  Fail: 3 //审核不通过
};

export const TimeDatePicker = {
  START: 0, // 身份证 驾驶证 等 开始时间
  END: 1 // 结束时间
};

export const noPermission = ['mine-message'];
