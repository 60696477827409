<template>
  <svg :class="`icon ${svgClass ? svgClass : ''}`" v-bind="$attrs" aria-hidden="true">
    <use :xlink:href="`#icon-${name}`"></use>
  </svg>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Icon',
  props: {
    svgClass: String,
    name: String
  }
});
</script>
<style lang="less" scoped>
.icon-class {
  display: inline-block;
}
.icon {
  font-size: 0.16rem;
}
</style>
