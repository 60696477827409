import Vue from 'vue';
import Components from './components';
import VueCompositionApi from '@vue/composition-api';
import { Button, Dialog, Form, Field } from 'vant';

Vue.use(VueCompositionApi);
Vue.use(Components);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Field);
