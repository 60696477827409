export const PageEnum = {
  BASE_LOGIN: '/login',
  BASE_HOME: '/driver/waybill',
  BASE_MINE: '/mine',
  BASE_BANK: '/bank',
  BASE_VERTIFIED: '/autonym',
  BASE_DRIVER_AUTH: '/driver/driververfied',
  BASE_DRIVER_AUTH_RESULT: '/driver/driververfied/result',
  BASE_CAR_ADD: '/mine/car/addCar',
  BASE_CAR_LIST: '/mine/car',
  BASE_SOURCE: '/driver/source',

  // 银行卡
  BANK: '/bank',
  BANK_ADD: '/bank/addbankcard',
  BANK_CHANGE_DEFAULT: '/bank/changedefaultbank',
  // 余额提现
  BALANCE: '/balance',
  BALANCE_WITHDRAW: '/balance/withdraw',
  BALANCE_RECORDS: '/balance/records',
  BALANCE_DETAIL: '/balance/detail',
  BALANCE_DETAILINFO: '/balance/detailinfo',
  // 个体司机车队
  DRIVER_FLEET: '/driver/fleet',
  DRIVER_FLEET_JOIN: '/driver/fleet/join',
  DRIVER_FLEET_RESULT: '/driver/fleet/result',
  DRIVER_FLEET_DETAIL: '/driver/fleet/detail',
  DRIVER_WAYBILL: '/driver/waybill',
  DRIVER_WAYBILL_DETAIL: '/driver/waybill/detail',

  CARRIER_FLEET: '/carrier/fleet',
  CARRIER_FLEET_DRIVER: '/carrier/fleet/driver',
  CARRIER_FLEET_TRUCK: '/carrier/fleet/truck',
  CARRIER_FLEET_INVITE_DRIVER: '/carrier/fleet/invite',
  CARRIER_FLEET_INVITE_DRIVER_RESULT: '/carrier/fleet/invite/result',

  SOURCE_CARRIER_SELECT: '/driver/source/carrierSelect',
  SOURCE_INFORMATION: '/driver/source/information',
  SOURCE_DETAIL: '/driver/source/detail',
  WAYBILL_GOODSINFO: '/driver/waybill/goodsInfo',

  CONTRACT_LIST: '/mine/contract',
  CONTRACT_ONLINE: '/mine/contract/online',

  CUSTOMER_CONSULTING: '/customer/consulting',
  CUSTOMER_EDIT: '/customer/edit',
  CUSTOMER_DETAIL: '/customer/detail',

  AGREEMENT: '/agreement',

  ID_VERIFY_INDEX: '/verify',
  ID_VERIFY_PAGE: '/verify/verify',
  ID_VERIFY_DETAIL: '/verify/detail'
};
