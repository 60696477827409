import ClientApp from '@/client/index';
import UA from '@/client/ua';
import { whiteList, noLoginWhiteList } from '@/constants';
import { isLogin, hasRealnameVerified } from '@/core/cache/auth';
import router from '@/router/.invoke/router';
import { PageEnum } from '@/enums/pageEnum';
import { useUserStore } from '@/store/user';

export function createPageGuard() {
  router.beforeResolve(async (_, from, next) => {
    console.log('触发---');
    const userStore = useUserStore();
    if (isLogin() && from.matched.length < 1 && from.name === null) {
      await userStore.getUserInfoAction();
    }
    if (isLogin()) {
      console.log(userStore.reviewLoaded);
      if (!userStore.reviewLoaded) {
        await userStore.getPlatformConfigAction();
      }
    }
    next();
  });
  router.afterEach(async (to, from) => {
    if (UA.androidClient) {
      if (from.fullPath !== '/' && to.fullPath !== from.fullPath) {
        if (Object.keys(whiteList).includes(to.fullPath)) {
          ClientApp.jumpNativePage(whiteList[to.fullPath].index);
        }
      }
    } else {
      if (!isLogin()) {
        if (to.path !== '/login' && !noLoginWhiteList.includes(to.path)) {
          router.replace('/login');
        }
      } else {
        if (!hasRealnameVerified() && to.path !== PageEnum.BASE_VERTIFIED) {
          router.replace(PageEnum.BASE_VERTIFIED);
        }
      }
    }
  });
}
