import Vue from 'vue';
import '@/core/install';
import '@/core/instruction';
import awaitWrap from '@/core/await';
import routerBack from '@/core/routerBack';

import '@wlhy-web-styles/mobile';
import '@/styles/index.less';

// 集成tailwind css 覆盖掉 @wlhy-web-styles/mobile 主题色
import '@/styles/tailwind.css';

import '@/client/WebViewJavascriptBridge';
import eruda from 'eruda';
import { optionalChaining } from '@/utils/optional';
import useScrollIntoView from './hook/useScrollIntoView';
import App from './App.vue';

import { Popup, DatetimePicker, Swipe, SwipeItem, Icon } from 'vant';
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Icon);

import router from '@/router/.invoke/router';
import { setupRouterGuard } from '@/router/guard';
import { setupGlobDirectives } from '@/directives';
import { setupErrorHandle } from './core/error-handle';
import UA from '@/client/ua';
import { PiniaPlugin, createPinia } from 'pinia';
import { syncDriverInfo } from './driver';
Vue.use(PiniaPlugin);
const pinia = createPinia();

useScrollIntoView();

// 移动端调试工具;
if (eruda && +process.env.VUE_APP_DEBUGGING === 1 && UA.androidClient) {
  eruda.init();
}

Vue.config.productionTip = false;

Vue.prototype.$$ = optionalChaining; // template模板中可选链的hash写法 别问为什么我用两个$ 因为爷喜欢

Vue.prototype.$EventBus = new Vue(); // 注册全局事件总线

Vue.prototype.$await = awaitWrap; // 包装await

Vue.prototype.$routerBack = routerBack; // 注册路由返回方法

const enter = () => {
  const app = new Vue({
    pinia,
    router,
    render: h => h(App)
  });
  setupRouterGuard();

  setupGlobDirectives();
  setupErrorHandle();
  app.$mount('#app');
};

if (UA.androidClient) {
  syncDriverInfo().then(enter);
} else {
  enter();
}
