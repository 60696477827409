import UA from '@/client/ua';
import ClientApp from '@/client/index';
import router from '../router/.invoke/router';
let methods;
if (UA.androidClient) {
  methods = ClientApp.finish;
} else {
  methods = router.back;
}
export default methods;
