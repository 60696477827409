import { nextTick, onMounted, onActivated } from '@vue/composition-api';

export function onMountedOrActivated(hook) {
  let mounted = false;
  onMounted(() => {
    hook();
    nextTick(() => {
      mounted = true;
    });
  });

  onActivated(() => {
    if (mounted) {
      hook();
    }
  });
}
