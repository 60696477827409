<template>
  <div class="page" ref="pageRef" v-bind="$attrs">
    <div
      class="page__background"
      ref="bgRef"
      :style="{
        top: `${navbarHeight}px`,
        bottom: `${toolbarHeight}px`
      }"
    ></div>
    <div
      class="page__content"
      :class="{ [bgClass]: true }"
      ref="contentRef"
      :style="{
        top: `${navbarHeight}px`,
        bottom: `${toolbarHeight}px`
      }"
    >
      <slot></slot>
      <div class="page__skeleton" v-show="loading && !empty">
        <van-skeleton v-for="(_, index) in [1, 2, 3, 4]" :key="index" class="py-16" title :row="3" />
      </div>
      <div v-show="empty && !loading" class="page__empty w-full h-full ">
        <div v-if="$slots.empty" class="flex flex-col justify-center align-center"><slot name="empty"></slot></div>
        <div v-else class="w-full h-full flex flex-col justify-center align-center">
          <div><img :src="icon" /></div>
          <p class="mt-16">{{ desc }}</p>
        </div>
      </div>
    </div>

    <div v-if="$slots.toolbar" class="page__toolbar" ref="toolbarRef">
      <slot name="toolbar"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick, onMounted, ref, unref } from '@vue/composition-api';
import { onMountedOrActivated } from '@/core/life';

import { Skeleton } from 'vant';
export default defineComponent({
  name: 'Page',
  components: {
    [Skeleton.name]: Skeleton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    empty: {
      type: Boolean,
      default: false
    },

    icon: null,
    desc: {
      type: String,
      default: ''
    },
    bgClass: String
  },
  setup() {
    // const navbarRef = ref();
    const toolbarRef = ref();
    const pageRef = ref();
    const navbarHeight = ref(0);
    const toolbarHeight = ref(0);

    onMounted(() => {
      const pageEl = unref(pageRef);

      const bgEl = pageEl.getElementsByClassName('page__background')[0];
      const contentEl = pageEl.getElementsByClassName('page__content')[0];

      const navbarEl = pageEl.getElementsByClassName('page__navbar')[0];

      if (navbarEl) {
        contentEl.removeChild(navbarEl);
        pageEl.insertBefore(navbarEl, bgEl);
      }
      calcContentHeight();
    });

    onMountedOrActivated(() => {
      nextTick(() => {
        calcContentHeight();
      });
    });

    function calcContentHeight() {
      const pageEl = unref(pageRef);
      const toolbar = unref(toolbarRef);
      const navbarEl = pageEl.getElementsByClassName('van-nav-bar')[0];
      navbarHeight.value = 0;
      toolbarHeight.value = 0;

      if (navbarEl) {
        navbarHeight.value = navbarEl.offsetHeight ?? 0;
      }

      if (toolbar) {
        toolbarHeight.value = toolbar.offsetHeight ?? 0;
      }
    }

    return {
      pageRef,
      toolbarRef,
      navbarHeight,
      toolbarHeight
    };
  }
});
</script>

<style lang="less">
.page {
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: visible;
  overflow-y: hidden;
  color: #1f1f21;

  .navbar + .page__background {
    top: 44px;
  }

  .page__background {
    background-color: rgba(241, 243, 245, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
  }

  .page__content {
    background-color: transparent;
    background: transparent;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    box-sizing: border-box;
  }

  .page__toolbar {
    background: white;
    box-shadow: 0px 1px 0px 0px rgba(188, 188, 188, 0.5) inset;
    height: 0.72rem;
    padding: 0.12rem 0.32rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .page__empty {
    background-color: white;
    img {
      width: 1rem;
    }
  }

  .page__skeleton {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
  }
}
</style>
