var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({ref:"pageRef",staticClass:"page"},'div',_vm.$attrs,false),[_c('div',{ref:"bgRef",staticClass:"page__background",style:({
      top: (_vm.navbarHeight + "px"),
      bottom: (_vm.toolbarHeight + "px")
    })}),_c('div',{ref:"contentRef",staticClass:"page__content",class:( _obj = {}, _obj[_vm.bgClass] = true, _obj ),style:({
      top: (_vm.navbarHeight + "px"),
      bottom: (_vm.toolbarHeight + "px")
    })},[_vm._t("default"),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading && !_vm.empty),expression:"loading && !empty"}],staticClass:"page__skeleton"},_vm._l(([1, 2, 3, 4]),function(_,index){return _c('van-skeleton',{key:index,staticClass:"py-16",attrs:{"title":"","row":3}})}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.empty && !_vm.loading),expression:"empty && !loading"}],staticClass:"page__empty w-full h-full "},[(_vm.$slots.empty)?_c('div',{staticClass:"flex flex-col justify-center align-center"},[_vm._t("empty")],2):_c('div',{staticClass:"w-full h-full flex flex-col justify-center align-center"},[_c('div',[_c('img',{attrs:{"src":_vm.icon}})]),_c('p',{staticClass:"mt-16"},[_vm._v(_vm._s(_vm.desc))])])])],2),(_vm.$slots.toolbar)?_c('div',{ref:"toolbarRef",staticClass:"page__toolbar"},[_vm._t("toolbar")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }