<template>
  <div style="height:0.44rem;" class="page__navbar">
    <NavBar
      :title="title"
      :left-arrow="leftArrow"
      :border="border"
      :fixed="true"
      z-index="99"
      @click-left="handleClickLeft"
      @click-right="handleClickRight"
    >
      <template #right>
        <slot name="right" v-if="hideRight"></slot>
      </template>
    </NavBar>
  </div>
</template>

<script>
import { NavBar } from 'vant';
import { defineComponent, onMounted } from '@vue/composition-api';
import UA from '@/client/ua';
import router from '@/router/.invoke/router';
import ClientApp from '@/client/index';

export default defineComponent({
  name: 'Navbar',
  components: { NavBar },
  props: {
    title: {
      default: '',
      type: String
    },
    leftArrow: {
      default: true,
      type: Boolean
    },
    border: {
      default: true,
      type: Boolean
    },
    background: {
      default: '#fff',
      type: String
    },
    customClickLeft: {
      default: false,
      type: Boolean
    },
    hideRight: {
      default: true,
      type: false
    }
  },
  setup(props, context) {
    // 点击左侧按钮
    const handleClickLeft = () => {
      // 自定义返回
      if (props.customClickLeft) {
        context.emit('clickLeft');
      } else {
        // 如果是在客户端环境，调用客户端返回方法
        const startPath = router.history._startLocation.split('?')[0];
        if (UA.androidClient && router.history.current.path === startPath) {
          ClientApp.goBack();
        } else {
          router.back();
        }
      }
    };

    onMounted(() => {
      document.getElementsByClassName('van-nav-bar')[0].style.background = props.background;
    });

    // 点击导航右侧按钮
    const handleClickRight = () => {
      context.emit('clickRight');
    };

    return { handleClickLeft, handleClickRight };
  }
});
</script>

<style lang="less">
@nav-bar-title-font-size: 12px !important;
.van-nav-bar__title {
  line-height: @nav-bar-height;
}
</style>
