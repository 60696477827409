import Vue from 'vue';

function formatComponentName(vm) {
  if (vm.$root === vm) {
    return {
      name: 'root',
      path: 'root'
    };
  }

  const options = vm.$options;
  if (!options) {
    return {
      name: 'anonymous',
      path: 'anonymous'
    };
  }
  const name = options.name || options._componentTag;
  return {
    name: name,
    path: options.__file
  };
}

function vueErrorHandler(err, vm, info) {
  const { name, path } = formatComponentName(vm);
  console.log(name, path);
}

export function scriptErrorHandler(event, source, lineno, colno, error) {
  if (event === 'Script error.' && !source) {
    return false;
  }
  const errorInfo = {};
  colno = colno || (window.event && window.event.errorCharacter) || 0;
  errorInfo.message = event;
  if (error?.stack) {
    errorInfo.stack = error.stack;
  } else {
    errorInfo.stack = '';
  }
  const name = source ? source.substr(source.lastIndexOf('/') + 1) : 'script';
  console.log(name);
  return true;
}

export function setupErrorHandle() {
  // script error
  Vue.config.errorHandler = vueErrorHandler;

  window.onerror = scriptErrorHandler;
}
