// 放置一些全局会大量会用的公共组件 全局注册
import Icon from '@components/Icon';
import NavBar from '@components/NavBar';
import Empty from '@components/Empty';
import Page from '@components/Page';

// 组件map
const components = [Icon, NavBar, Empty, Page];

const install = function(Vue) {
  if (install.installed) return; // 遍历注册 如果已注册直接返回
  components.map(component => Vue.component(component.name, component));
};

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default {
  install,
  ...components
};
