import Vue from 'vue';

const replaceString = (str, start, end, char) => {
  if (!str) return '';
  return `${str.substring(0, start)}${char}${str.substring(end)}`;
};

Vue.directive('hideTel', {
  inserted(el, binding) {
    el.innerHTML = replaceString(binding.value, 3, 7, '****');
  },
  update(el, binding) {
    el.innerHTML = replaceString(binding.value, 3, 7, '****');
  }
});
