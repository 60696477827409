import { PageEnum } from '@/enums/pageEnum';
import router from '@/router/.invoke/router';
import { useUserStore } from '@/store/user';

export function createStateGuard() {
  router.afterEach(to => {
    const userStore = useUserStore();
    if (to.path === PageEnum.BASE_LOGIN) {
      userStore.resetState();
    }
  });
}
