const DEFAULT_CACHE_TIME = 60 * 60 * 24 * 7;
import { toRaw } from '@vue/composition-api';
import { Memory } from './memory';
import { createLocalStorage, createSessionStorage } from '@/core/cache';
import { TOKEN_KEY } from '@/enums/cacheEnum';

const ls = createLocalStorage();
const ss = createSessionStorage();
const localMemory = new Memory(DEFAULT_CACHE_TIME);
const sessionMemory = new Memory(DEFAULT_CACHE_TIME);

export class Persistent {
  static getLocal(key) {
    return localMemory.get(key)?.value;
  }

  static setLocal(key, value, immediate = false) {
    localMemory.set(key, toRaw(value));
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      immediate && ls.set(token, localMemory.getCache);
    }
  }

  static removeLocal(key) {
    localMemory.remove(key);
  }

  static clearLocal() {
    localMemory.clear();
  }

  static getSession(key) {
    return sessionMemory.get(key)?.value;
  }

  static setSession(key, value, immediate = false) {
    sessionMemory.set(key, toRaw(value));
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      immediate && ss.set(token, sessionMemory.getCache);
    }
  }

  static removeSession(key) {
    sessionMemory.remove(key);
  }
  static clearSession() {
    sessionMemory.clear();
  }

  static clearAll() {
    sessionMemory.clear();
    localMemory.clear();
  }
}

window.addEventListener('beforeunload', function() {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    ls.set(token, localMemory.getCache);
    ss.set(token, sessionMemory.getCache);
  }
});

function initPersistentMemory() {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    const localCache = ls.get(token);
    const sessionCache = ss.get(token);
    localCache && localMemory.resetCache(localCache);
    sessionCache && sessionMemory.resetCache(sessionCache);
  }
}

initPersistentMemory();
