<template>
  <div id="app">
    <router-view>
      <template #default="{ Component, route }">
        <keep-alive v-if="route.meta && route.meta.keepAlive">
          <component :is="Component" :key="route.fullPath" />
        </keep-alive>
        <component v-else :is="Component" :key="route.fullPath" />
      </template>
    </router-view>
  </div>
</template>
<script>
import '@wlhy-web-styles/mobile';

export default {
  mounted() {
    console.log(process.env.VUE_APP_BASEURL);
  }
};
</script>
<style lang="less">
#app {
  font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  font-size: 0.14rem;
  color: #1d1d1d;
  min-height: 100vh;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.empty-btn {
  width: 3.27rem;
  height: 0.48rem;
  border: 1px solid #0076ff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 0.04rem;
  color: #0076ff;
  font-size: 0.16rem;
}
</style>
