<template>
  <div class="empty-wrap">
    <img :style="{ height: imgHeight, width: imgWidth }" :src="image" />
    <p class="mt-16" :style="{ color: colorBlack ? '#282828' : '#bcbcbc;' }">{{ description }}</p>
    <slot name="extra"> </slot>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Empty',
  components: {},
  props: {
    imgWidth: {
      default: '',
      type: String
    },
    colorBlack: {
      default: false,
      type: Boolean
    },
    imgHeight: {
      default: '',
      type: String
    },
    image: {
      default: '',
      type: String
    },
    description: {
      default: '',
      type: String
    }
  },
  setup() {
    return {};
  }
});
</script>

<style lang="less" scoped>
.empty-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
