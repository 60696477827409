import { http } from '../utils/http';

export const getCode = async params => {
  return await http['post']('/api/account-platform/login/sendCode', params, false);
};

export const apiLogin = async params => {
  return await http['post']('/api/account-platform/login/register', params);
};

export const getPlatformConfigApi = async () => {
  return await http['post']('/api/resource/platform/config/getPlatformInfo', {});
};
