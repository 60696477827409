export const createStorage = ({ prefixKey = '', storage = sessionStorage, timeout = null }) => {
  const WebStorage = class WebStorage {
    constructor() {
      this.storage = storage;
      this.prefixKey = prefixKey;
    }

    getKey(key) {
      return `${this.prefixKey}${key}`.toUpperCase();
    }

    set(key, value, expire = timeout) {
      const stringifyValue = JSON.stringify({
        value,
        time: Date.now(),
        expire: expire ? new Date().getTime() + expire * 1000 : null
      });
      this.storage.setItem(this.getKey(key), stringifyValue);
    }

    get(key, def = null) {
      try {
        const val = this.storage.getItem(this.getKey(key));
        if (!val) return def;
        const data = JSON.parse(val);
        const { value, expire } = data;
        if (!!expire || expire >= new Date().getTime()) {
          return value;
        }
        this.remove(key);
      } catch (error) {
        return def;
      }
    }

    remove(key) {
      this.storage.removeItem(this.getKey(key));
    }

    clear() {
      this.storage.clear();
    }
  };

  return new WebStorage();
};
