import { defineStore } from 'pinia';
import { apiLogin, getPlatformConfigApi } from '@api/login';
import { getUserInfo } from '@/api/mine';
import router from '@/router/.invoke/router';
import { getUserIdentity } from '@/core/cache/auth';

import {
  setRefreshToken as cacheRefreshToken,
  setToken as cachToken,
  setUserInfo as cacheUserInfo,
  setUserIdentity as cacheUserIdentity
} from '@/core/cache/auth';
import { PageEnum } from '@/enums/pageEnum';
import { IdentityEnum } from '@/enums/identityEnum';
import { Persistent } from '@/core/cache/persistent';

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    userInfo: {
      platformAuditPostpositionState: 1,
      driverAuthVO: {}, // 司机认证
      driverPhone: '',
      carrierInfoVO: {}, // 车队长信息
      driverId: null,
      realNameAuthVO: {}, // 实名认证
      truckNum: 0, // 车辆数量
      bankCardNum: 0, // 银行卡数量
      informationMobileDriverTrailerInfo: 0
    },
    identity: getUserIdentity() || IdentityEnum.DRIVER, // 司机身份 DRIVER: 个体 CARRIER: 车队长
    token: '',
    refreshToken: '',
    reviewLoaded: false,
    reviewOpen: false,
    platformConfig: null
  }),
  getters: {
    driverAuthVO() {
      return this.userInfo.driverAuthVO;
    },
    isTrailerOpen() {
      return this.userInfo?.informationMobileDriverTrailerInfo === 1;
    },
    platformAuditPostpositionState() {
      return this.userInfo.platformAuditPostpositionState;
    },

    idCard() {
      return this.userInfo.realNameAuthVO.idCard;
    },

    driverId() {
      return this?.userInfo?.driverAuthVO?.driverId;
    },

    realNameAuthVO() {
      return this.userInfo.realNameAuthVO;
    },

    realNameAuthStatus() {
      return this.userInfo.realNameAuthVO.authStatus || -1;
    },

    realName() {
      return this.userInfo.realNameAuthVO.name || '';
    },

    driverPhone() {
      return this.userInfo.driverPhone;
    },

    isDriver() {
      return this.identity === IdentityEnum.DRIVER;
    },

    getIdentity() {
      return this.identity;
    },

    carrierInfo() {
      return this.userInfo.carrierInfoVO;
    },
    showReviewKeys() {
      return this.reviewLoaded && this.reviewOpen;
    }
  },
  actions: {
    setToken(val) {
      this.token = val;
      cachToken(val);
    },

    setRefreshToken(val) {
      this.refreshToken = val;
      cacheRefreshToken(val);
    },

    setUserInfo(val) {
      this.userInfo = val;
      cacheUserInfo(val);
    },

    setUserIdentity(val) {
      this.identity = val;
      cacheUserIdentity(val);
    },

    resetState() {
      this.userInfo = null;
      this.token = '';
      this.refreshToken = '';
    },

    async login(params) {
      try {
        const res = await apiLogin(params);
        const { data } = res;
        const { access_token, refresh_token } = data;
        this.setToken(access_token);
        this.setRefreshToken(refresh_token);
        const userInfo = await this.getUserInfoAction();
        const { realNameAuthVO } = userInfo;
        if (!realNameAuthVO) {
          router.replace('/autonym');
        } else {
          // 优先选择身份再进行实名认证
          router.replace('/identityswitch');
        }
        return userInfo;
      } catch (error) {
        return null;
      }
    },

    async getUserInfoAction() {
      try {
        const res = await getUserInfo();
        const { data } = res;
        this.setUserInfo(data);
        this.setUserIdentity(getUserIdentity()); // 设置用户身份
        return data;
      } catch (error) {
        return null;
      }
    },

    async logout(goLogin = false) {
      localStorage.clear();
      sessionStorage.clear();
      Persistent.clearAll();
      goLogin && router.push(PageEnum.BASE_LOGIN);
    },
    async getPlatformConfigAction() {
      try {
        const res = await getPlatformConfigApi();
        const { data } = res || {};
        if (data) {
          this.platformConfig = data;
          this.reviewOpen = data?.reviewSwitch === 1;
        } else {
          this.platformConfig = null;
          this.reviewOpen = false;
        }
      } catch (e) {
        console.log(e);
        this.platformConfig = null;
        this.reviewOpen = false;
      } finally {
        this.reviewLoaded = true;
      }
    }
  }
});

export function useUserStoreWithout() {
  return defineStore({ id: 'user' });
}
