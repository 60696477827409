import Vue from 'vue';
import Router from 'vue-router';
import apis from '@/apis';;
Vue.use(Router);
export const routes = [{
    component: () => import('@/views/agreement/index.vue'),
    name: 'agreement',
    path: '/agreement',
  },
  {
    component: () => import('@/views/autonym/index.vue'),
    name: 'autonym',
    path: '/autonym',
  },
  {
    component: () => import('@/views/autonym/detail/index.vue'),
    name: 'autonym-detail',
    path: '/autonym/detail',
  },
  {
    component: () => import('@/views/balance/index.vue'),
    name: 'balance',
    path: '/balance',
  },
  {
    component: () => import('@/views/balance/detail/index.vue'),
    name: 'balance-detail',
    path: '/balance/detail',
  },
  {
    component: () => import('@/views/balance/detailinfo/index.vue'),
    name: 'balance-detailinfo',
    path: '/balance/detailinfo',
  },
  {
    component: () => import('@/views/balance/records/index.vue'),
    name: 'balance-records',
    path: '/balance/records',
  },
  {
    component: () => import('@/views/balance/withdraw/index.vue'),
    name: 'balance-withdraw',
    path: '/balance/withdraw',
  },
  {
    component: () => import('@/views/bank/index.vue'),
    name: 'bank',
    path: '/bank',
  },
  {
    component: () => import('@/views/bank/addbankcard/index.vue'),
    name: 'bank-addbankcard',
    path: '/bank/addbankcard',
  },
  {
    component: () => import('@/views/bank/changedefaultbank/index.vue'),
    name: 'bank-changedefaultbank',
    path: '/bank/changedefaultbank',
  },
  {
    component: () => import('@/views/carrier/fleet/index.vue'),
    name: 'carrier-fleet',
    path: '/carrier/fleet',
  },
  {
    component: () => import('@/views/carrier/fleet/driver/index.vue'),
    name: 'carrier-fleet-driver',
    path: '/carrier/fleet/driver',
  },
  {
    component: () => import('@/views/carrier/fleet/invite/index.vue'),
    name: 'carrier-fleet-invite',
    path: '/carrier/fleet/invite',
  },
  {
    component: () => import('@/views/carrier/fleet/invite/result/index.vue'),
    name: 'carrier-fleet-invite-result',
    path: '/carrier/fleet/invite/result',
  },
  {
    component: () => import('@/views/carrier/fleet/truck/index.vue'),
    name: 'carrier-fleet-truck',
    path: '/carrier/fleet/truck',
  },
  {
    component: () => import('@/views/customer/index.vue'),
    name: 'customer',
    path: '/customer',
  },
  {
    component: () => import('@/views/customer/consulting/index.vue'),
    name: 'customer-consulting',
    path: '/customer/consulting',
  },
  {
    component: () => import('@/views/customer/detail/index.vue'),
    name: 'customer-detail',
    path: '/customer/detail',
  },
  {
    component: () => import('@/views/customer/edit/index.vue'),
    name: 'customer-edit',
    path: '/customer/edit',
  },
  {
    component: () => import('@/views/download/index.vue'),
    name: 'download',
    path: '/download',
  },
  {
    component: () => import('@/views/driver/driververfied/index.vue'),
    name: 'driver-driververfied',
    path: '/driver/driververfied',
  },
  {
    component: () => import('@/views/driver/driververfied/result/index.vue'),
    name: 'driver-driververfied-result',
    path: '/driver/driververfied/result',
  },
  {
    component: () => import('@/views/driver/fleet/index.vue'),
    name: 'driver-fleet',
    path: '/driver/fleet',
  },
  {
    component: () => import('@/views/driver/fleet/detail/index.vue'),
    name: 'driver-fleet-detail',
    path: '/driver/fleet/detail',
  },
  {
    component: () => import('@/views/driver/fleet/join/index.vue'),
    name: 'driver-fleet-join',
    path: '/driver/fleet/join',
  },
  {
    component: () => import('@/views/driver/fleet/result/index.vue'),
    name: 'driver-fleet-result',
    path: '/driver/fleet/result',
  },
  {
    component: () => import('@/views/driver/privacy/index.vue'),
    name: 'driver-privacy',
    path: '/driver/privacy',
  },
  {
    component: () => import('@/views/driver/source/index.vue'),
    name: 'driver-source',
    path: '/driver/source',
  },
  {
    component: () => import('@/views/driver/source/carrierSelect/index.vue'),
    name: 'driver-source-carrierSelect',
    path: '/driver/source/carrierSelect',
  },
  {
    component: () => import('@/views/driver/source/detail/index.vue'),
    name: 'driver-source-detail',
    path: '/driver/source/detail',
  },
  {
    component: () => import('@/views/driver/source/information/index.vue'),
    name: 'driver-source-information',
    path: '/driver/source/information',
  },
  {
    component: () => import('@/views/driver/source/search/index.vue'),
    name: 'driver-source-search',
    path: '/driver/source/search',
  },
  {
    component: () => import('@/views/driver/sourceway/index.vue'),
    name: 'driver-sourceway',
    path: '/driver/sourceway',
  },
  {
    component: () => import('@/views/driver/waybill/index.vue'),
    name: 'driver-waybill',
    path: '/driver/waybill',
  },
  {
    component: () => import('@/views/driver/waybill/contract/index.vue'),
    name: 'driver-waybill-contract',
    path: '/driver/waybill/contract',
  },
  {
    component: () => import('@/views/driver/waybill/detail/index.vue'),
    name: 'driver-waybill-detail',
    path: '/driver/waybill/detail',
  },
  {
    component: () => import('@/views/driver/waybill/goodsInfo/index.vue'),
    name: 'driver-waybill-goodsInfo',
    path: '/driver/waybill/goodsInfo',
  },
  {
    component: () => import('@/views/driver/waybill/search/index.vue'),
    name: 'driver-waybill-search',
    meta: {
      keepAlive: true,
    },
    path: '/driver/waybill/search',
  },
  {
    component: () => import('@/views/driver/waybill/test/index.vue'),
    name: 'driver-waybill-test',
    path: '/driver/waybill/test',
  },
  {
    component: () => import('@/views/identityswitch/index.vue'),
    name: 'identityswitch',
    path: '/identityswitch',
  },
  {
    component: () => import('@/views/login/index.vue'),
    name: 'login',
    path: '/login',
  },
  {
    component: () => import('@/views/mine/index.vue'),
    name: 'mine',
    path: '/mine',
  },
  {
    component: () => import('@/views/mine/authorization/index.vue'),
    name: 'mine-authorization',
    path: '/mine/authorization',
  },
  {
    component: () => import('@/views/mine/car/index.vue'),
    name: 'mine-car',
    path: '/mine/car',
  },
  {
    component: () => import('@/views/mine/car/addCar/index.vue'),
    name: 'mine-car-addCar',
    path: '/mine/car/addCar',
  },
  {
    component: () => import('@/views/mine/car/detail/index.vue'),
    name: 'mine-car-detail',
    path: '/mine/car/detail',
  },
  {
    component: () => import('@/views/mine/car/trailer/detail/index.vue'),
    name: 'mine-car-trailer-detail',
    path: '/mine/car/trailer/detail',
  },
  {
    component: () => import('@/views/mine/car/trailer/edit/index.vue'),
    name: 'mine-car-trailer-edit',
    path: '/mine/car/trailer/edit',
  },
  {
    component: () => import('@/views/mine/contract/index.vue'),
    name: 'mine-contract',
    path: '/mine/contract',
  },
  {
    component: () => import('@/views/mine/contract/online/index.vue'),
    name: 'mine-contract-online',
    path: '/mine/contract/online',
  },
  {
    component: () => import('@/views/mine/message/index.vue'),
    name: 'mine-message',
    path: '/mine/message',
  },
  {
    component: () => import('@/views/mine/message/online/index.vue'),
    name: 'mine-message-online',
    path: '/mine/message/online',
  },
  {
    component: () => import('@/views/real-name-result/index.vue'),
    name: 'realNameResult',
    path: '/realNameResult',
  },
  {
    component: () => import('@/views/search/index.vue'),
    name: 'search',
    path: '/search',
  },
  {
    component: () => import('@/views/service/index.vue'),
    name: 'service',
    path: '/service',
  },
  {
    component: () => import('@/views/signed/index.vue'),
    name: 'signed',
    path: '/signed',
  },
  {
    component: () => import('@/views/statement/index.vue'),
    name: 'statement',
    path: '/statement',
  },
  {
    component: () => import('@/views/verify/index.vue'),
    name: 'verify',
    path: '/verify',
  },
  {
    component: () => import('@/views/verify/detail/index.vue'),
    name: 'verify-detail',
    path: '/verify/detail',
  },
  {
    component: () => import('@/views/verify/verify/index.vue'),
    name: 'verify-verify',
    path: '/verify/verify',
  },
  {
    path: '/',
    redirect: '/driver/waybill'
  },

  {
    path: '/driver/waybill',
    redirect: '/waybill'
  },

  {
    path: '/driver/source',
    redirect: '/source'
  },

  {
    path: '/balance',
    redirect: '/mine/balance'
  },

  {
    path: '/bank',
    redirect: '/mine/bank'
  },

  {
    name: 'notFound',
    path: '*',
    component: () => import('@/views/NotFound.vue')
  },
];
const router = new Router({
  mode: 'history',
  routes,
});
export default router;