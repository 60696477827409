export const TOKEN_KEY = 'TOKEN__';
export const REFRESH_TOKEN_KEY = 'REFRESH__TOKEN__';
// user info key
export const USER_INFO_IDENTITY = 'USER__IDENTITY__';

export const USER_INFO_KEY = 'USER__INFO__';

export const USER_VERTIFIED_INFO_KEY = 'USER__VERTIFIED__INFO__';

export const DRIVER_VERSION_INFO_KEY = 'DRIVER__VERTIFIED__INFO__';

export const USER_BANK_CARD_KEY = 'USER__BANK__CARD__KEY__';

export const TRUCK_LIST_KEY = 'TRUCK__LIST__KEY__';

export const TRUCK_VERTIFIED_INFO_KEY = 'TRUCK__VERTIFIED__INFO__KEY__';
