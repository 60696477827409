import { createStorage as create } from './storageCache';

export const DEFAULT_CACHE_TIME = 60 * 60 * 24 * 7;
const createOptions = (storage = sessionStorage, options = {}) => {
  return {
    storage,
    prefixKey: 'WLHYOS__',
    ...options
  };
};
export const createStorage = (storage = sessionStorage, options = {}) => {
  return create(createOptions(storage, options));
};

export const createLocalStorage = (options = {}) => {
  return createStorage(localStorage, { ...options, timeout: DEFAULT_CACHE_TIME });
};

export const createSessionStorage = (options = {}) => {
  return createStorage(sessionStorage, { ...options, timeout: DEFAULT_CACHE_TIME });
};
