import { TOKEN_KEY, REFRESH_TOKEN_KEY, USER_INFO_KEY, USER_INFO_IDENTITY } from '@/enums/cacheEnum';
import { Persistent } from '@/core/cache/persistent';

//是否登录
export function isLogin() {
  return !!getToken();
}

//是否实名认证
export function hasRealnameVerified() {
  const userInfo = getUserInfo();
  return !!userInfo && !!userInfo.realNameAuthVO;
}

//是否司机认证
export function hasDriverVerified() {
  const userInfo = getUserInfo();
  const { driverAuthVO } = userInfo;
  return !!driverAuthVO;
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY) || null;
}

export function setToken(val) {
  localStorage.setItem(TOKEN_KEY, val);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function setRefreshToken(val) {
  localStorage.setItem(REFRESH_TOKEN_KEY, val);
}

export function getUserInfo() {
  return getAuthCache(USER_INFO_KEY);
}

export function setUserInfo(val) {
  return setAuthCache(USER_INFO_KEY, val);
}

export function getAuthCache(key) {
  const fn = Persistent.getLocal;
  return fn(key);
}

export function setAuthCache(key, value) {
  const fn = Persistent.setLocal;
  return fn(key, value, true);
}

// 设置用户身份的缓存值
export function setUserIdentity(value) {
  return localStorage.setItem(USER_INFO_IDENTITY, value);
}

// 获取用户身份 车队长:CARRIER 个体司机: PERSONAL
export function getUserIdentity() {
  return localStorage.getItem(USER_INFO_IDENTITY);
}
