import { Toast } from 'vant';
import UA from './ua';
import router from '@/router/.invoke/router';
class ClientAppMixin {
  // 仿照vue的路由方式
  constructor() {
    this.baseUrl = 'wlhyos-driver://native/';
    this.outBaseUrl = 'wlhyos-driver://native/pdf';
  }
  push(data) {
    const { path, params } = data;
    const hrefPath = `${this.baseUrl}${path}/${params.type}`;
    window.location.href = hrefPath;
  }

  jumpNativePage(params, subIndex, main) {
    if (UA.androidClient) {
      const hrefPath = `${this.baseUrl}${main || 'main'}?index=${params}&subIndex=${subIndex || '0'}`;
      window.location.href = hrefPath;
    } else {
      router.back();
    }
  }
  getDeviceId() {
    const re = syncAndroidSend('getDeviceId');
    return re;
  }
  // 路由返回
  finish(refresh = false) {
    const re = syncAndroidSend('finish', { refresh });
    return re;
  }
  // 跳转页面
  jumpPage(params) {
    const hrefPath = `${this.baseUrl}web?url=${params.path}`;
    window.location.href = hrefPath;
  }
  // 请求token
  requestToken() {
    const re = syncAndroidSend('requestToken');
    return re;
  }
  // 调用相册
  openAlbum(params) {
    Toast.loading({
      duration: 0, // 持续展示 toast
      message: '上传中...'
    });
    const re = syncAndroidSend('openAlbum', params);
    console.log(re);
    if (!re) {
      Toast.clear();
    }
    return re;
  }
  takePhoto(params) {
    Toast.loading({
      duration: 0, // 持续展示 toast
      message: '上传中...'
    });
    const re = syncAndroidSend('takePhoto', params);
    if (!re) {
      Toast.clear();
    }
    return re;
  }
  // 装货确认前校验
  startShippingInfo(params) {
    const re = syncAndroidSend('startShippingInfo', params);
    return re;
  }
  // 卸货确认前校验
  stopShippingInfo(params) {
    const re = syncAndroidSend('stopShippingInfo', params);
    return re;
  }
  // 切换账号
  switchAccount() {
    const re = syncAndroidSend('switchAccount');
    return re;
  }
  // 刷新司机信息
  refreshDriverInfo() {
    const re = syncAndroidSend('refreshDriverInfo');
    return re;
  }
  // 路由返回
  goBack() {
    const re = syncAndroidSend('goBack');
    return re;
  }
  // 回去当前经纬度
  getLocation() {
    const re = syncAndroidSend('getLocation');
    return re;
  }

  showUserQRCode(params) {
    const re = syncAndroidSend('showUserQRCode', params);
    return re;
  }

  // previewFile(params) {
  //   const re = syncAndroidSend('previewFile', params);
  //   return re;
  // }

  startScan() {
    const re = syncAndroidSend('startScan');
    return re;
  }

  statusBarColor(params) {
    const re = syncAndroidSend('statusBarColor', params);
    return re;
  }
}

const syncAndroidSend = async (tag, params = '') => {
  if (!UA.androidClient) {
    Toast('非客户端环境');
    return Promise.reject({ message: '非客户端环境' });
  }
  if (!window.WebViewJavascriptBridge) {
    Toast('JSbrige通讯失败');
    return Promise.reject({ message: 'JSbrige通讯失败' });
  }
  return new Promise((resolve, reject) => {
    window.WebViewJavascriptBridge.callHandler(tag, params, data => {
      Toast.clear();
      data = JSON.parse(data);
      if (data.code === '00000' && data.data) {
        if (tag === 'openAlbum' || tag === 'takePhoto') {
          data.data.forEach(item => {
            if (item.url.includes('http')) {
              const imgEncodeAry = data.data[0].url.split('?');
              data.data[0].url = `${imgEncodeAry[0]}?${encodeURIComponent(imgEncodeAry[1])}`;
            } else {
              // TODO: file文件另外定义
            }
          });
        }
        resolve(data.data);
      } else {
        if (tag !== 'openAlbum' || tag !== 'takePhoto') {
          Toast(data.message);
        }
        switch (data.code) {
          // 登录信息失效，重新登录
          case '1007':
            ClientApp.switchAccount();
            return;
        }
        reject(data);
      }
    });
  });
};

const ClientApp = new ClientAppMixin();

export default ClientApp;
